const window = global.window as any;
export const callWAReq = () => {
    postCallWAReq();
};

function postCallWAReq() {
    try {
        if (!sessionStorage.getItem('__tmpRef')) {
            if (document.referrer?.length > 0) {
                sessionStorage.setItem('__tmpRef', document.referrer);
            }
        }
        clearWAParam();

        const pvar = {
            content: '0',
            server: 'secure-sg',
            page_url: document.location,
            referrer: sessionStorage.getItem('__tmpRef'),
        };

        WATrackerTrackPageView(pvar);
        sessionStorage.setItem('__tmpRef', document.location.toString());
    } catch (e) {
        console.log(e);
    }
}

const clearWAParam = () => {
    try {
        window.s.pageName = undefined;
        window.s.prop1 = undefined;
        window.s.prop10 = undefined;
        window.s.eVar10 = undefined;
        window.s.eVar31 = undefined;
        window.s.prop7 = undefined;
        window.s.eVar7 = undefined;

        if (sessionStorage.getItem('__tmpRef')) {
            window.s.prop6 = sessionStorage.getItem('__tmpRef');
        }
    } catch (e) {
        console.log(e);
    }
};

// Wa 页面切换
export const WATrackerTrackPageView = (eventId: any) => {
    if (typeof window.WATracker !== 'undefined' && window.WATracker) {
        try {
            window.WATracker.trackPageView(eventId.replace(/\s/g, ''));
        } catch (err) {
            console.log('WATrackerTrackPageView-----', err);
        }
    }
};

// Wa 点击事件
export const WATrackerClickEvn = (eventId: any) => {
    // console.log('eventId', eventId)
    if (typeof window.WATracker !== 'undefined' && window.WATracker) {
        try {
            window.WATracker.trackClickEvent(eventId.replace(/\s/g, ''));
        } catch (err) {
            console.log('trackClickEvent-----', err);
        }
    }
};

export const getButtonLinkWithCID = (link?: string, pageID?: string, noPrefix?: boolean) => {
    if (!link) return;
    let buttonLink = '';
    const genLink = () => {
        buttonLink =
            link.indexOf('?') > -1
                ? `${link}&b_cid=${noPrefix ? '' : 'SPLDSP_RnE/HW/'}${pageID || ''}`
                : `${link}?b_cid=${noPrefix ? '' : 'SPLDSP_RnE/HW/'}${pageID || ''}`;
    };
    if (link.match(/^\/(faq|dining|parties|plan-your-visit|album|season-highlight)?/)) {
        genLink();
        return buttonLink;
    }
    if (!link.includes('hkjc.com')) {
        return link;
    }
    genLink();
    return buttonLink.replace(/\s/g, '');
};
