'use client';

import {
    createContext,
    ReactNode,
    useContext,
    useState,
    useEffect,
    Dispatch,
    SetStateAction,
} from 'react';
import { usePathname } from 'next/navigation';

import { GlobalSettingQueryResult, InLineBusModalQueryResult } from '@/@types/generated/sitecore';

type CurrentTheme = {
    themeColor: string;
    fontColor: string;
    musicWaveColor: string;
    buttonBGColor: string;
    overlayBGColor: string;
    primaryButtonBGColor: string;
    primaryButtonIconColor: string;
    secondaryButtonBGColor: string;
    secondaryButtonIconColor: string;
    highlightColor: string;
    h2Color: string;
};

type StoreType =
    | {
          setStore: Dispatch<SetStateAction<StoreType>>;
          setCurrentTheme: Dispatch<SetStateAction<CurrentTheme | undefined>>;
          setIsLoading: Dispatch<SetStateAction<boolean>>;
          setGlobalStore: (storeData: Partial<StoreType>) => void;
          isLoading: boolean;
          globalSettingData?: GlobalSettingQueryResult['globalSettings'];
          lang?: 'en-US' | 'zh-HK' | 'zh-CN';
          //   lang?: 'en-US' | 'zh-HK';
          currentTheme?: CurrentTheme;
          pageID?: string;
          inLineBusData?: InLineBusModalQueryResult
      }
    | undefined;

let storeValue: StoreType = undefined;

export const GlobalContext = createContext<StoreType>(storeValue);

export default function GlobalProvider({ children, data }: { children: ReactNode; data: any }) {
    const [store, setStore] = useState<StoreType>();
    const [currentTheme, setCurrentTheme] = useState<CurrentTheme>();
    const [isLoading, setIsLoading] = useState(true);
    const pathname = usePathname();

    const setGlobalStore = (storeData: Partial<StoreType>) => {
        if (!storeData) return;
        setStore({ ...data, ...store, ...storeData } as StoreType);
    };

    useEffect(() => {
        Object.assign(storeValue || {}, data);
        setStore({ ...storeValue, ...data });
    }, [data]);

    useEffect(() => {
        if (!data || pathname.match(/parties\/./)) {
            setIsLoading(false);
            return;
        }
        const theme = {
            themeColor:
                data?.globalSettingData?.masterColorCode?.targetItem?.themeColorCode?.value || '',
            fontColor: data?.globalSettingData?.masterColorCode?.targetItem?.fontColor?.value || '',
            musicWaveColor:
                data?.globalSettingData?.masterColorCode?.targetItem?.musicWaveColor?.value || '',
            buttonBGColor:
                data?.globalSettingData?.masterColorCode?.targetItem?.buttonBGColor?.value || '',
            overlayBGColor:
                data?.globalSettingData?.masterColorCode?.targetItem?.overlayBGColor?.value || '',
            primaryButtonBGColor:
                data?.globalSettingData?.masterColorCode?.targetItem?.primaryButtonBGColor?.value ||
                '',
            primaryButtonIconColor:
                data?.globalSettingData?.masterColorCode?.targetItem?.primaryButtonIconColor
                    ?.value || '',
            secondaryButtonBGColor:
                data?.globalSettingData?.masterColorCode?.targetItem?.secondaryButtonBGColor
                    ?.value || '',
            secondaryButtonIconColor:
                data?.globalSettingData?.masterColorCode?.targetItem?.secondaryButtonIconColor
                    ?.value || '',
            highlightColor:
                data?.globalSettingData?.masterColorCode?.targetItem?.highlightColor?.value || '',
            h2Color: data?.globalSettingData?.masterColorCode?.targetItem?.h2Color?.value || '',
        };
        setTimeout(() => {
            setCurrentTheme(theme);
            setIsLoading(false);
        }, 100);
    }, [pathname, data]);
    return (
        <GlobalContext.Provider
            value={{
                ...(store || {}),
                currentTheme,
                setStore,
                setCurrentTheme,
                setIsLoading,
                setGlobalStore,
                isLoading,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
}

export function useGlobalStore() {
    const store = useContext(GlobalContext);
    if (!store) {
        throw new Error('You have forgot to use Provider');
    }
    return store;
}
