'use client';

import { ZoomInOutlined } from '@ant-design/icons';
import { Image, ImageProps } from 'antd';
import cn from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import { ImagePreviewType } from 'rc-image/lib/Image';
import {
    JSXElementConstructor,
    ReactElement,
    ReactNode,
    useState,
    useRef,
    useEffect,
    cloneElement,
    useCallback,
} from 'react';
import VideoPlay from '@/components/VideoIframe';
import PlayIcon from '../SVG/PlayIcon';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import Dialog from '../Dialog';
import style from './index.module.scss';
import { mobileBreakPoint, withFullPath } from '@/utils/utils';
import useWindowSize from '@/utils/useScreenSize';

export interface imageProps {
    setting?: ImageProps;
    canPreview?: boolean;
    mediaOption?: {
        mediaType?: String;
        videoSrc?: string;
        videoOptions?: any;
        mediaCaption?: string;
    };
    previewProps?: ImagePreviewType;
    wrapClass?: string;
}

interface imageGroupProps extends imageProps {
    children?: ReactNode;
    imageList?: {
        id?: number;
        src?: string;
        mediaType?: String;
        videoSrc?: string;
        mediaCaption?: string;
    }[];
    previewProps?: ImagePreviewType;
    total?: number;
    currentPreviewIndex?: number;
    handleVisibleChange?: (visible: boolean) => void;
}

const ImageRender = (params: {
    originalNode: ReactElement<any, string | JSXElementConstructor<any>>;
    currentMedia: {
        id?: number;
        src?: string;
        mediaType?: String;
        videoSrc?: string;
        videoOptions?: any;
        mediaCaption?: string;
    };
    visible?: boolean;
    setVisible?: (visible: boolean) => void;
    current?: number;
    total?: number;
}) => {
    const { originalNode, currentMedia, visible, setVisible, current = 0, total } = params;
    const windowSize = useWindowSize();
    const prevRef = useRef<HTMLDivElement>(null);
    const nextRef = useRef<HTMLDivElement>(null);
    const isMobile = windowSize.width < mobileBreakPoint;
    const isPortal = isMobile && windowSize.width < windowSize.height;
    const isLandscape = isMobile && windowSize.width > windowSize.height;
    const screeWidth = isPortal ? windowSize.width : windowSize.height;
    const mobilePicWidth = (screeWidth || 398) - 80;
    const imageRef = originalNode?.props?.imgRef?.current;
    const moveIcon = () => {
        setTimeout(() => {
            if (prevRef.current && nextRef.current) {
                const prevX = prevRef.current?.getBoundingClientRect().x;
                const nextX = nextRef.current?.getBoundingClientRect().x;
                const leftArrow = document.querySelector('.ant-image-preview-switch-left') as any;
                const rightArrow = document.querySelector('.ant-image-preview-switch-right') as any;
                if (leftArrow) {
                    leftArrow.style.left = `${prevX ? prevX - 40 : 0}px`;
                }
                if (rightArrow) {
                    rightArrow.style.left = `${nextX ? nextX : 0}px`;
                }
            }
        }, 500);
    };

    useEffect(() => {
        if (visible) {
            if (imageRef) {
                moveIcon();
            }
            if (currentMedia?.mediaType === 'Video') {
                moveIcon();
            }
        }
    }, [
        imageRef,
        visible,
        current,
        isLandscape,
        windowSize.width,
        windowSize.height,
        currentMedia?.mediaType,
    ]);

    return currentMedia?.mediaType === 'Video' ? (
        <Dialog
            data={{
                setting: {
                    open: visible,
                    onCancel: () => {
                        setVisible && setVisible(false);
                    },
                    // closable: false,
                    closeIcon: (
                        <CloseOutlined
                            className={cn(
                                'text-base-font-color absolute top-[-20px] right-0 text-xl',
                                isMobile ? 'top-[-20px] right-[-16px]' : 'top-[-20px] right-0',
                            )}
                        />
                    ),
                    destroyOnClose: true,
                    width: isMobile ? '85%' : 900,
                    style: {
                        top: 0,
                        border: 'none',
                        boxShadow: 'none',
                        background: 'none',
                    },
                    classNames: {
                        content: cn('w-full flex justify-center items-center'),
                        body: 'max-h-full',
                    },
                },
                className: 'h-full flex items-center',
            }}
        >
            <div className='relative w-full'>
                <div ref={prevRef} className='absolute' />
                <VideoPlay
                    localHref={window.location.href}
                    videoLink={currentMedia?.videoSrc}
                    videoAutoPlay={true}
                    className={cn('w-full')}
                    height={
                        isMobile
                            ? isLandscape
                                ? windowSize.height * 0.65
                                : mobilePicWidth / 1.7
                            : undefined
                    }
                />
                <div ref={nextRef} className='absolute right-0' />
                <div className='text-base-font-color flex justify-between px-3 py-1'>
                    <div>{currentMedia.mediaCaption || ''}</div>
                    <div>{`${current + 1} / ${total}`}</div>
                </div>
            </div>
        </Dialog>
    ) : (
        <div className={cn('relative', style.imagePreviewWrap)}>
            <div ref={prevRef} className='absolute' />
            {originalNode}
            <div ref={nextRef} className='absolute right-0' />
            <CloseOutlined
                className={cn(
                    'text-base-font-color absolute top-[-40px] text-xl',
                    isMobile ? 'right-[-28px]' : 'right-[-40px]',
                )}
                onClick={() => {
                    setVisible && setVisible(false);
                }}
            />
            <div className='w-full text-base-font-color flex justify-between px-3 py-1 absolute top-[100%]'>
                <div>{currentMedia.mediaCaption || ''}</div>
                {total && <div>{`${current + 1} / ${total}`}</div>}
            </div>
        </div>
    );
};
export const ImagePreviewGroup = ({
    setting,
    children,
    canPreview = true,
    imageList,
    previewProps = {},
    total,
    currentPreviewIndex,
    handleVisibleChange,
}: imageGroupProps) => {
    const [visible, setVisible] = useState(false);
    const [forceRender, setForceRender] = useState(false);
    const [currentIndex, setCurrent] = useState<number | undefined>(undefined);

    useEffect(() => {
        currentPreviewIndex !== undefined && setCurrent(currentPreviewIndex);
    }, [currentPreviewIndex]);

    return (
        <Image.PreviewGroup
            preview={
                canPreview && {
                    visible,
                    current: currentIndex,
                    forceRender: forceRender,
                    rootClassName: style.imageGroupWrap,
                    movable: false,
                    minScale: 1,
                    maxScale: 1,
                    toolbarRender: (
                        _,
                        { transform: { scale }, actions: { onZoomOut, onZoomIn } },
                    ) => null,
                    imageRender: (originalNode, info) => {
                        const Node = cloneElement(originalNode, {
                            src: imageList
                                ? withFullPath(imageList[info.current]?.src)
                                : originalNode?.props?.src,
                        });
                        return (
                            visible && (
                                <ImageRender
                                    originalNode={Node}
                                    currentMedia={(imageList && imageList[info.current]) || {}}
                                    visible={visible}
                                    setVisible={(visible) => {
                                        setForceRender(true);
                                        setVisible(visible);
                                    }}
                                    current={info.current}
                                    total={total}
                                />
                            )
                        );
                    },
                    onVisibleChange: (visible) => {
                        setVisible(visible);
                        setForceRender(true);
                        handleVisibleChange && handleVisibleChange(visible);
                    },
                    closeIcon: null,
                    onChange:
                        currentPreviewIndex !== undefined
                            ? (current) => {
                                  setCurrent(current);
                              }
                            : undefined,
                    ...previewProps,
                }
            }
            {...setting}
        >
            {children}
        </Image.PreviewGroup>
    );
};

const ImagePreview = ({
    setting,
    canPreview = false,
    mediaOption,
    previewProps,
    wrapClass,
}: imageProps) => {
    const [visible, setVisible] = useState(false);
    const [forceRender, setForceRender] = useState(false);
    const globalStore = useGlobalStore();
    const iconBgColor = globalStore.currentTheme?.secondaryButtonBGColor;
    const iconColor = globalStore.currentTheme?.secondaryButtonIconColor;
    return (
        <div
            className={cn(
                'w-full relative',
                mediaOption?.mediaType === 'Video' && 'text-center',
                wrapClass,
            )}
        >
            <Image
                alt=''
                preview={
                    canPreview && {
                        visible,
                        forceRender: forceRender,
                        closeIcon: null,
                        mask:
                            mediaOption?.mediaType === 'Video' ? (
                                <></>
                            ) : (
                                <ZoomInOutlined style={{ fontSize: 36 }} />
                            ),
                        movable: false,
                        minScale: 1,
                        maxScale: 1,
                        toolbarRender: (
                            _,
                            { transform: { scale }, actions: { onZoomOut, onZoomIn } },
                        ) => null,
                        imageRender: (originalNode) => (
                            <ImageRender
                                originalNode={originalNode}
                                currentMedia={mediaOption || {}}
                                visible={visible}
                                setVisible={(visible) => {
                                    setForceRender(true);
                                    setVisible(visible);
                                }}
                            />
                        ),
                        onVisibleChange: (visible, preValue) => {
                            setVisible(visible);
                            setForceRender(true);
                            previewProps?.onVisibleChange &&
                                previewProps?.onVisibleChange(visible, preValue);
                        },
                        ...previewProps,
                    }
                }
                {...setting}
                src={withFullPath(setting?.src)}
            />
            {mediaOption?.mediaType === 'Video' && (
                <div className='absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] pointer-events-none'>
                    <PlayIcon bgColor={iconBgColor} iconColor={iconColor}></PlayIcon>
                </div>
            )}
        </div>
    );
};

export default ImagePreview;
