import * as React from 'react';

type iconProps = {
    iconColor?: string;
    bgColor?: string;
};

const PlayIconXS = ({ iconColor = '#000', bgColor = '#EAE219' }: iconProps) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={26} height={26} fill='none'>
        <circle cx={13} cy={13} r={13} fill={bgColor} />
        <path fill={iconColor} d='m19.5 13-9.75 5.63V7.37L19.5 13Z' />
    </svg>
);

export default PlayIconXS;
