'use client';
// https://common.hkjc.com/videoplayer_v3/video-player-iframe_v3.aspx?rf=https://pv-football.corp.hkjc.com/football/chinese/multimedia&videourl=football/2023/2023-fb/3mins20231011/football_2023-fb_3mins20231011.smil&bgcolor=ffffff&ismultiangle=false&lang=cht&autostart=true&qualitybtn=true&posterimage=&themecolor=D9AD00

import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import { isValidUrl } from '@/utils/utils';

interface videoPlayProp {
    localHref?: string;
    videoLink?: string;
    videoPoster?: string;
    isPopup?: boolean;
    videoAutoPlay?: boolean;
    language?: string;
    isMultiAngle?: boolean;
    isShowQualityBtn?: boolean;
    bgColor?: string;
    themeColor?: string;
    className?: string;
    height?: number;
}

const VideoPlay = ({
    localHref,
    videoLink,
    videoPoster,
    isPopup,
    videoAutoPlay,
    language,
    isMultiAngle = false,
    isShowQualityBtn = true,
    bgColor = 'ffffff',
    themeColor = 'D9AD00',
    className,
    height,
}: videoPlayProp) => {
    const [videoSrc, setVideoSrc] = useState('');
    const domain = 'https://common.hkjc.com';
    const globalStore = useGlobalStore();
    const themeColorCode = globalStore.currentTheme?.themeColor || '';
    const lang = globalStore.lang === 'en-US' ? 'eng' : 'cht';
    useEffect(() => {
        setVideoSrc(
            isValidUrl(videoLink)
                ? videoLink || ''
                : `${domain}/videoplayer_v3/video-player-iframe_v3.aspx?rf=${localHref}&videourl=${videoLink}&bgcolor=${bgColor}&ismultiangle=${isMultiAngle}` +
                      `&lang=${lang || language}&autostart=${videoAutoPlay}&qualitybtn=${isShowQualityBtn}&posterimage=${videoPoster}&themecolor=${themeColorCode.replace('#', '') || themeColor}`,
        );
    }, [
        localHref,
        videoLink,
        videoPoster,
        isPopup,
        videoAutoPlay,
        language,
        isShowQualityBtn,
        isMultiAngle,
        bgColor,
        themeColor,
        lang,
        themeColorCode,
    ]);

    return (
        <div className={cn('videoContainer aspect-w-16 aspect-h-9', className)}>
            <iframe
                src={videoSrc}
                width={'100%'}
                height={height || 450}
                allowFullScreen
                allow='autoplay; encrypted-media'
                loading='lazy'
            />
        </div>
    );
};

export default VideoPlay;
