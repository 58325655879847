// 'use client'
import React from 'react';
import { ConfigProvider, ThemeConfig } from 'antd';

const testGreenColor = '#0075FF';
const testRedColor = '#ff0000';

const withTheme = (node: JSX.Element, theme?: ThemeConfig) => (
    <>
        <ConfigProvider
            theme={{
                // token: {
                //     // colorPrimary: '#0075FF',
                //     colorPrimary: '#EAE219',
                //     colorTextLightSolid: '#000',
                // },
                ...(theme || {}),
            }}
        >
            {node}
        </ConfigProvider>
    </>
);

export default withTheme;
