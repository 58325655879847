'use client';

import React, { CSSProperties, useState } from 'react';
import { Button, ButtonProps, Flex, ThemeConfig } from 'antd';
import { useRouter } from 'next/navigation';
import withTheme from '@/theme';
import cn from 'classnames';
import ArrowRightIcon from '@public/images/common/arrow_right_icon.svg';
import ArrowRightIconPrimary from '@public/images/common/arrow_right_icon_primary.svg';
import Text from '@componentsText';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import Dialog from '../Dialog';
import LeaveSiteType from '../Dialog/LeaveSiteType';
import ArrowRightBgIcon from '@/components/SVG/ArrowRightIcon';
import PlayIconXS from '../SVG/PlayIconXS';
// import SVG from '@componentsSVG';

type CustButtonProps = {
    data: {
        className?: string;
        setting?: ButtonProps;
        theme?: ThemeConfig;
        btnType?: string;
        btnSpacing?: string;
        buttonLink?: string;
        currentThemeColor?: any;
        style?: CSSProperties;
    };
    // currentMusicWaveColor?: string;
    children?: React.ReactNode;
};

export const GeneralButtonType = (props: {
    label?: string;
    icon?: 'arrowRightBK' | 'arrowRightPrimary' | 'playIcon';
    className?: string;
    fontColor?: string;
    iconBGColor?: string;
    iconColor?: string | '#EAE219';
}) => {
    const globalStore = useGlobalStore();

    const iconBGColor = globalStore?.currentTheme?.primaryButtonBGColor;
    const iconColor = globalStore?.currentTheme?.primaryButtonIconColor;
    const secondaryIconBGColor = globalStore?.currentTheme?.secondaryButtonBGColor;
    const secondaryIconColor = globalStore?.currentTheme?.secondaryButtonIconColor;

    const iconType = {
        arrowRightBK: (
            <ArrowRightBgIcon
                bgColor={props.iconBGColor || iconBGColor}
                iconColor={props.iconColor || iconColor}
            />
        ),
        arrowRightPrimary: (
            <ArrowRightBgIcon
                bgColor={props.iconBGColor || secondaryIconBGColor}
                iconColor={props.iconColor || secondaryIconColor}
            />
        ),
        playIcon: (
            <PlayIconXS
                bgColor={props.iconBGColor || secondaryIconBGColor}
                iconColor={props.iconColor || secondaryIconColor}
            />
        ),
    };

    const fontColor = globalStore?.currentTheme?.fontColor;
    return (
        <Flex gap={10} align={'center'}>
            {props.label && (
                <Text
                    className={cn(
                        props.className,
                        'font-extrabold leading-[19px] xmd:leading-[22px]',
                    )}
                    level={'body2'}
                    text={props.label}
                    style={{ color: props.fontColor || fontColor }}
                />
            )}
            {props.icon && iconType[props.icon]}
        </Flex>
    );
};

const CustButton = ({ data, children }: CustButtonProps) => {
    const { theme: themeProps } = data;
    const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
    const globalStore = useGlobalStore();
    const router = useRouter();
    const { lang } = globalStore;
    const showLeaveModal = () => {
        setIsLeaveModalOpen(true);
    };

    const handleLeaveCancel = () => {
        setIsLeaveModalOpen(false);
    };
    const defaultBtnSpacing =
        data.setting?.type == 'link'
            ? '!p-0'
            : data.btnSpacing || '!px-[19px] !py-[11px] xmd:!px-[26px] xmd:!py-4';

    const buttonBGColor = globalStore.currentTheme?.buttonBGColor;
    const defaultTheme = {
        components: {
            Button: {
                colorPrimary: buttonBGColor,
                colorPrimaryHover: buttonBGColor,
                colorPrimaryActive: buttonBGColor,
            },
        },
    };

    const handleButtonClick: React.MouseEventHandler<HTMLElement> = (e) => {
        if (data.buttonLink) {
            data.setting?.onClick && data.setting?.onClick(e);
            if (
                data.buttonLink.match(
                    /^\/(faq|dining|parties|plan-your-visit|album|season-highlight)?/,
                )
            ) {
                router.push(`/${lang}${data.buttonLink}`);
                return;
            }
            if (!data.buttonLink.includes('hkjc.com')) {
                showLeaveModal();
                return;
            }
            window.open(data.buttonLink);
            return;
        }
        data.setting?.onClick && data.setting?.onClick(e);
    };

    const defaultSetting: ButtonProps = {
        className: cn(
            defaultBtnSpacing,
            '!h-fit !w-fit !rounded font-heading-base hover:opacity-80',
            data.className,
        ),
        htmlType: 'button',
        type: 'primary',
        ...data.setting,
        style: { ...data.currentThemeColor, ...data.style },
        onClick: handleButtonClick,
    };
    const theme = themeProps || defaultTheme;
    return withTheme(
        <>
            <Button {...defaultSetting}>{children}</Button>
            <Dialog
                data={{
                    setting: {
                        open: isLeaveModalOpen,
                        onCancel: handleLeaveCancel,
                        width: 500,
                    },
                    className: cn('border-[#EAE219] shadow-[0_0_10px_0_#EAE219]'),
                }}
            >
                <LeaveSiteType
                    onConfirm={(e) => {
                        window.open(data.buttonLink);
                        // data.setting?.onClick && data.setting?.onClick(e);
                        handleLeaveCancel();
                    }}
                    targetUrl={data.buttonLink}
                />
            </Dialog>
        </>,
        theme,
    );
};

export default CustButton;
