import * as React from 'react';

type iconProps = {
    iconColor?: string;
    bgColor?: string;
};

const PlayIcon = ({ iconColor = '#001B34', bgColor = '#EAE219' }: iconProps) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={50} height={50} fill='none'>
        <g opacity={0.8}>
            <circle cx={25} cy={25} r={25} fill={bgColor} opacity={0.8} />
            <path fill={iconColor} d='M40 25 17.5 37.99V12.01L40 25Z' opacity={0.8} />
        </g>
    </svg>
);
export default PlayIcon;
