'use client';
import { useEffect, useState } from 'react';

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });

    function handleResize() {
        if (window.innerWidth !== windowSize.width) {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

export default useWindowSize;
