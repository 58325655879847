'use client';
import withTheme from '@/theme';
import { ThemeConfig } from 'antd';
import { CSSProperties } from 'react';
import cn from 'classnames';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import useWindowSize from '@/utils/useScreenSize';
import { mobileBreakPoint } from '@/utils/utils';

type headingProp = {
    level: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
    text?: string | React.ReactNode;
    className?: string;
    style?: CSSProperties;
    theme?: ThemeConfig;
};

const HeadingMapping = {
    1: {
        tagName: 'h1',
        dynamicClass: `text-heading-1 leading-heading-1 tracking-heading-1`,
        mobileDynamicClass: `text-heading-mobile-1 leading-heading-mobile-1 tracking-heading-mobile-1`,
    },
    2: {
        tagName: 'h2',
        dynamicClass: `text-heading-2 leading-heading-2 tracking-heading-2`,
        mobileDynamicClass: `text-heading-mobile-2 leading-heading-mobile-2 tracking-heading-mobile-2`,
    },
    3: {
        tagName: 'h3',
        dynamicClass: `text-heading-3 leading-heading-3 tracking-heading-3`,
        mobileDynamicClass: `text-heading-mobile-3 leading-heading-mobile-3 tracking-heading-mobile-3`,
    },
    4: {
        tagName: 'h4',
        dynamicClass: `text-heading-4 leading-heading-4 tracking-heading-4`,
        mobileDynamicClass: `text-heading-mobile-4 leading-heading-mobile-4 tracking-heading-mobile-4`,
    },
    5: {
        tagName: 'h5',
        dynamicClass: `text-heading-5 leading-heading-5 tracking-heading-5`,
        mobileDynamicClass: `text-heading-mobile-5 leading-heading-mobile-5 tracking-heading-mobile-5`,
    },
    6: {
        tagName: 'h6',
        dynamicClass: `text-heading-6 leading-heading-6 tracking-heading-6`,
        mobileDynamicClass: `text-heading-6 leading-heading-6 tracking-heading-6`,
    },
    7: {
        tagName: 'p',
        dynamicClass: `text-heading-7 leading-heading-7 tracking-heading-7`,
        mobileDynamicClass: `text-heading-7 leading-heading-7 tracking-heading-7`,
    },
    8: {
        tagName: 'p',
        dynamicClass: `text-heading-8 leading-heading-8 tracking-heading-8`,
        mobileDynamicClass: `text-heading-8 leading-heading-8 tracking-heading-8`,
    },
};

const Heading = ({ level = 1, text, className, style, theme: themeProps }: headingProp) => {
    const globalStore = useGlobalStore();
    const isMobile = useWindowSize().width < mobileBreakPoint;
    if (!HeadingMapping[level]) return null;
    const h2Color = globalStore.currentTheme?.h2Color;
    const Component = HeadingMapping[level].tagName as keyof JSX.IntrinsicElements;
    const defaultTheme = {};
    const theme = themeProps || defaultTheme;
    return withTheme(
        <Component
            className={cn(
                'font-heading-base not-italic font-extrabold rounded-[5px]',
                isMobile
                    ? HeadingMapping[level].mobileDynamicClass
                    : HeadingMapping[level].dynamicClass,
                className,
            )}
            style={{ color: level === 2 ? h2Color : '#FFFFFF', ...style }}
        >
            {text}
        </Component>,
        theme,
    );
};

export default Heading;
