import * as React from 'react';

type iconProps = {
    iconColor?: string;
    bgColor?: string;
};

const ArrowRightIcon = ({ iconColor = '#001B34', bgColor = '#EAE219' }: iconProps) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={26} height={26} fill='none'>
        <circle cx={13} cy={13} r={13} fill={bgColor} />
        <path
            fill={iconColor}
            fillRule='evenodd'
            d='M16.343 12.37 12.08 8.108 13.1 7.09 19.01 13l-5.91 5.91-1.018-1.018 4.081-4.082H7.19v-1.44h9.154Z'
            clipRule='evenodd'
        />
    </svg>
);
export default ArrowRightIcon;
