'use client';
import cn from 'classnames';
import { CSSProperties } from 'react';

export type textProp = {
    level: 'body1' | 'body2' | 'body3' | 'body4' | 'subtitle1';
    text: React.ReactNode;
    className?: string;
    style?: CSSProperties;
};

const dynamicClass = {
    body1: 'text-body1 leading-body1',
    body2: 'text-body2 leading-body2',
    body3: 'text-body3 leading-body3',
    body4: 'text-body4 leading-body4',
    subtitle1: 'text-subtitle1 leading-subtitle1',
};

const Text = ({ level, text, className, style }: textProp) => {
    return (
        <div
            className={cn(
                `inline font-text-base not-italic rounded-[5px] ${dynamicClass[level]}`,
                className,
            )}
            style={style}
        >
            {text}
        </div>
    );
};

export default Text;
