import { NextRequest } from 'next/server';

export const isRichText = (str: string) => {
    const htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
    return htmlRegex.test(str);
};

export const mediaStringFormat = (dataString: string, req: NextRequest) => {
    try {
        const mediaDomain = new URL(process.env.SITECORE_GRAPH_URL).origin;
        const mediaBaseUrl = mediaDomain || `${req.nextUrl.protocol}//${req.nextUrl.host}`;
        const result = dataString.replace(/\/?(-\/media\/)/g, `${mediaBaseUrl}/-/media/`);
        return JSON.parse(result);
    } catch (e) {
        console.error('mediaStringFormat error: ', e);
    }
};

export function withBasePath(subpath: string): string {
    const APP_BASE_PATH = process.env.NEXT_PUBLIC_APP_BASE_PATH;
    if (APP_BASE_PATH) {
        const path = `/${APP_BASE_PATH}${subpath}`;
        return path;
    } else {
        return subpath;
    }
    // return subpath;
}

export function withFullPath(path?: string): string {
    if (!path) {
        return path || '';
    }
    if (path.startsWith('http:') || path.startsWith('https:')) {
        return path;
    }
    const MEDIA_FULL_PATH = /(?=.*\/\/)(?=.*-\/media\/).*$/;
    if (MEDIA_FULL_PATH.test(path)) {
        return `https:${path}`;
    }
    return path;
}

/** 是否在时间范围内
 * startDate--开始时间
 * endDate----结束时间
 */
export const isTimeFrame = (startDate: number | undefined, endDate: number | undefined) => {
    if (!startDate || !endDate) return false;
    if (startDate <= 0 && endDate <= 0) {
        return true;
    }
    if (endDate < 0) {
        endDate = Number.MAX_SAFE_INTEGER;
    }
    const date = new Date().getTime();
    return date >= startDate && date < endDate;
};

/** 移动端尺寸 */
export const mobileBreakPoint = 992;

/** 过滤crossSellBanner对象 */
export const crossSellBannerData = (contentData: any) => {
    const content = contentData?.row
        .flatMap((row: any) => row.col)
        .flatMap((col: any) => col.pageContent)
        .filter((content: any) => content?.template?.name === 'CrossSellBanner');
    return content;
};

export function withHappyWedPrefix(subpath: string): string {
    const domain =
        process.env.NODE_ENV === 'development'
            ? process.env.NEXT_PUBLIC_LOCAL_ENDPOINT
            : process.env.NEXT_PUBLIC_WEB_ENDPOINT;
    const base_path = process.env.NEXT_PUBLIC_APP_BASE_PATH;
    if (domain) {
        const path = `${domain}${base_path}/${subpath}`;
        return path;
    } else {
        return subpath;
    }
    // return subpath;
}

export function toCamelCase(str: string) {
    return str.replace(/[-_\s](.)/g, function (match, group1) {
        return group1.toUpperCase();
    });
}

export const isValidUrl = (urlString?: string) => {
    if (!urlString) return false;
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$',
        'i',
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
};
