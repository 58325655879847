'use client';
import React, { CSSProperties } from 'react';
import type { ThemeConfig } from 'antd';
import { Modal, ModalProps } from 'antd';
import withTheme from '@/theme';
import cn from 'classnames';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import useWindowSize from '@/utils/useScreenSize';
import { mobileBreakPoint } from '@/utils/utils';
import style from './index.module.scss';

export type dialogProps = {
    data: {
        className?: string;
        theme?: ThemeConfig;
        setting?: ModalProps;
        style?: CSSProperties;
        currentThemeColor?: string;
        wrapClassName?: string;
    };
    children: React.ReactNode;
};

const Dialog = ({ data, children }: dialogProps) => {
    const { theme: themeProps } = data;
    const globalStore = useGlobalStore();
    const themeColorCode = globalStore?.currentTheme?.themeColor;
    const isMobile = useWindowSize().width < mobileBreakPoint;
    const defaultTheme = {
        token: {
            colorText: 'text-base-font-color',
            colorIcon: '#FFFFFF',
        },
        components: {
            Modal: {
                contentBg: 'transparent',
                boxShadow: '',
            },
        },
    };
    const defaultSetting: ModalProps = {
        className: cn(data.className, 'border-2 border-solid bg-primary-bg-color'),
        wrapClassName: cn(
            isMobile ? 'pt-[30px]' : 'pt-[65px]',
            style.modalWrap,
            data?.wrapClassName,
        ),
        style: {
            top: 0,
            border: `2px solid ${data?.currentThemeColor || themeColorCode}`,
            boxShadow: `0 0 10px 0 ${data?.currentThemeColor || themeColorCode}`,
            '--content-max-height': isMobile ? '100px' : '130px',
            "--scrollbar-thumb-color": themeColorCode,
        } as any,
        ...data.setting,
    };
    const theme = themeProps || defaultTheme;

    return withTheme(<Modal {...defaultSetting}>{children}</Modal>, theme);
};

export default Dialog;
