'use client';
import { CSSProperties, memo, useEffect, useRef, useState } from 'react';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import cn from 'classnames';
import Dialog from '../Dialog';
import LeaveSiteType from '../Dialog/LeaveSiteType';
import { useRouter } from 'next/navigation';
import { WATrackerClickEvn } from '@/utils/wa';
import InInLineBusType from '../Dialog/InLineBusType';
import styles from './index.module.scss';

type Props = {
    html: string;
    className?: string;
    style?: CSSProperties;
    currentThemeColor?: string;
    onClick?: () => void;
    statusChange?: any[];
};

// eslint-disable-next-line react/display-name
const RichText = memo(
    (props: Props) => {
        const { html, className, style, currentThemeColor, statusChange } = props;

        const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
        const [isInLineBusModalOpen, setIsInInLineBusModalOpen] = useState(false);
        const StatusRef = useRef<any>(null);
        const [href, setHref] = useState('');
        const router = useRouter();
        const globalStore = useGlobalStore();
        const themeColor = globalStore.currentTheme?.themeColor;
        const lang = globalStore.lang;

        const showLeaveModal = () => {
            setIsLeaveModalOpen(true);
        };

        const handleLeaveCancel = () => {
            setIsLeaveModalOpen(false);
            StatusRef.current = Math.random();
        };

        const showInInLineBusModal = () => {
            setIsInInLineBusModalOpen(true);
        };

        const handleInInLineBusCancel = () => {
            setIsInInLineBusModalOpen(false);
            StatusRef.current = Math.random();
        };

        const recoverATag = () => {
            const aTag = document.querySelectorAll('a');
            Array.from(aTag).forEach((item: any) => {
                const itemHref = item.getAttribute('href');
                if (itemHref) {
                    item.onclick = (e: any) => {
                        setHref(itemHref);
                        StatusRef.current = Math.random();
                        if (itemHref.includes('#inline_bus')) {
                            e.preventDefault();
                            e.stopPropagation();
                            showInInLineBusModal();
                            return;
                        }
                        if (itemHref.match(/^\/(faq|dining|parties|plan-your-visit|album|season-highlight)?/)) {
                            e.preventDefault();
                            e.stopPropagation();
                            router.push(`/${lang}${itemHref}`);
                            return;
                        }
                        if (!itemHref.includes('hkjc.com')) {
                            e.preventDefault();
                            e.stopPropagation();
                            showLeaveModal();
                            return;
                        }
                    };
                }
            });
        };

        useEffect(() => {
            setTimeout(() => {
                const themeClassNode = document.querySelectorAll('.theme-color');
                const bgThemeClassNode = document.querySelectorAll('.bg-theme-color');
                const waNonStandardTagNode = document.querySelectorAll('.waNonStandardTag');
                bgThemeClassNode.forEach((item: any) => {
                    item.style.backgroundColor = themeColor;
                });
                themeClassNode.forEach((item: any) => {
                    item.style.color = themeColor;
                });
                waNonStandardTagNode.forEach((item: any) => {
                    item.onclick = (e: any) => {
                        const eventID = item.getAttribute('data-wa');
                        StatusRef.current = Math.random();
                        WATrackerClickEvn(eventID);
                    };
                });

                recoverATag();
            }, 300);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [themeColor, lang, StatusRef?.current, ...(statusChange || [])]);

        useEffect(() => {
            if (currentThemeColor) {
                setTimeout(() => {
                    const themeClassNode = document.querySelectorAll('.theme-color');
                    themeClassNode.forEach((item: any) => {
                        item.style.color = currentThemeColor;
                    });
                }, 300);
            }
        }, [currentThemeColor]);

        return (
            <>
                <div
                    className={cn(styles.RichTextWrap, className)}
                    style={style}
                    dangerouslySetInnerHTML={{
                        __html: html || '',
                    }}
                />
                <Dialog
                    data={{
                        setting: {
                            open: isLeaveModalOpen,
                            onCancel: handleLeaveCancel,
                            width: 500,
                        },
                    }}
                >
                    <LeaveSiteType
                        onConfirm={(e) => {
                            window.open(href);
                            handleLeaveCancel();
                        }}
                        targetUrl={href}
                    />
                </Dialog>
                <Dialog
                    data={{
                        wrapClassName: styles.inLineBusModalWrap,
                        setting: {
                            open: isInLineBusModalOpen,
                            onCancel: handleInInLineBusCancel,
                            width: 768,
                        },
                    }}
                >
                    <InInLineBusType />
                </Dialog>
            </>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.html === nextProps.html &&
            prevProps.currentThemeColor === nextProps.currentThemeColor
        );
    },
);

export default RichText;
