'use client';
import { Flex } from 'antd';
import cn from 'classnames';
import Heading from '@componentsHeading';
import TitleIconRight from '@public/images/common/wave_icon_r.svg';
import Text from '@componentsText';
import CustButton, { GeneralButtonType } from '@componentsButton';
import { MouseEventHandler, useMemo } from 'react';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import style from './index.module.scss';
import RichText from '../RichText';
import WaveLeftIcon from '../SVG/WaveLeftIcon';
import { useTranslation } from '@/i18n/use-transition.client';

type leaveSiteProps = {
    data?: {
        title?: string;
        content?: string;
        targetSiteTitle?: string;
        targetUrl?: string;
        btn?: {
            label: string;
            url?: string;
        };
    };
    targetUrl?: string;
    onConfirm?: MouseEventHandler<HTMLElement>;
    currentMusicWaveColor?: string;
};

const LeaveSiteType = ({ onConfirm, targetUrl, currentMusicWaveColor }: leaveSiteProps) => {
    const globalStore = useGlobalStore();
    const { t } = useTranslation();
    const globalData = globalStore.globalSettingData;
    const dynamicStyle: any = {
        '--scrollbar-thumb-color': globalStore?.currentTheme?.themeColor,
    };
    const musicWaveColor = globalStore?.currentTheme?.musicWaveColor;

    const leaveContent = useMemo(() => {
        return (
            <RichText
                className={cn(style.leaveContentWrap)}
                style={dynamicStyle}
                html={globalData?.redirectPopupContent?.rendered || ''}
            />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalStore?.currentTheme?.themeColor, globalData?.redirectPopupContent?.rendered]);

    return (
        <>
            <Flex vertical gap={24} justify={'center'} align={'center'} className='mt-3'>
                <Flex vertical gap={8} align={'center'}>
                    <Heading
                        className={'text-base-font-color'}
                        level={3}
                        text={globalData?.redirectPopupTitle?.value}
                        style={{ color: '#FFFFFF' }}
                    />
                    <WaveLeftIcon iconColor={currentMusicWaveColor || musicWaveColor} />
                    {leaveContent}
                </Flex>
                <Flex vertical gap={16} align={'center'}>
                    <Text
                        level={'body3'}
                        text={t('leaveContinue')}
                        className={'text-base-font-color'}
                    />
                    <Text level={'body4'} text={targetUrl} className={'text-base-font-color'} />
                </Flex>
                <CustButton
                    data={{
                        setting: {
                            // href: data.btn.url,
                            onClick: onConfirm,
                        },
                    }}
                >
                    <GeneralButtonType label={globalData?.redirectButtonText?.value} />
                </CustButton>
            </Flex>
        </>
    );
};

export default LeaveSiteType;
