'use client';
import { Flex, Table, TableProps } from 'antd';
import cn from 'classnames';
import Heading from '@componentsHeading';
import Text from '@componentsText';
import { useMemo } from 'react';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import styles from './index.module.scss';
import WaveLeftIcon from '../SVG/WaveLeftIcon';
import { useTranslation } from '@/i18n/use-transition.client';
import withTheme from '@/theme';
import { InLineBusModalFragment } from '@/@types/generated/sitecore';

type InLineBusTypeTypeProps = {
    currentMusicWaveColor?: string;
};

type DataType = {
    key: number;
    routeNo: string | undefined;
    from: string | undefined;
    to: string | undefined;
    operators: string | undefined;
    combineFromAndTo: boolean | undefined;
};

const InLineBusTypeType = ({ currentMusicWaveColor }: InLineBusTypeTypeProps) => {
    const globalStore = useGlobalStore();
    const { t } = useTranslation();
    const inLineBusData = globalStore.inLineBusData;

    const musicWaveColor = globalStore?.currentTheme?.musicWaveColor;

    const columns: TableProps<DataType>['columns'] = [
        {
            title: t('inLineBusTableRouteNo'),
            dataIndex: 'routeNo',
        },
        {
            title: t('inLineBusTableFrom'),
            dataIndex: 'from',
            onCell: (record) => ({
                colSpan: record.combineFromAndTo ? 2 : 1,
            }),
        },
        {
            title: t('inLineBusTableTo'),
            dataIndex: 'to',
            onCell: (record) => ({
                colSpan: record.combineFromAndTo ? 0 : 1,
            }),
        },
        {
            title: t('inLineBusTableOperators'),
            dataIndex: 'operators',
        },
    ];

    const inLineBusModalData = useMemo(
        () =>
            (inLineBusData?.content?.children
                ? inLineBusData?.content?.children[0]
                : {}) as InLineBusModalFragment,
        [inLineBusData?.content?.children],
    );

    const data = useMemo(() => {
        if (!inLineBusModalData) return [];
        return inLineBusModalData?.tableData.map((item, index) => {
            const { routeNo, from, to, operators, combineFromAndTo } = item;
            return {
                key: index,
                routeNo: routeNo?.value,
                from: from?.value,
                to: to?.value,
                operators: operators?.value,
                combineFromAndTo: combineFromAndTo?.boolValue,
            };
        });
    }, [inLineBusModalData]);

    return withTheme(
        <>
            <Flex vertical gap={24} justify={'center'} align={'center'} className={cn('mt-3')}>
                <Flex vertical gap={8} align={'center'} className='w-full'>
                    <Heading
                        className={'text-base-font-color'}
                        level={3}
                        text={inLineBusModalData?.title?.value}
                        style={{ color: '#FFFFFF' }}
                    />
                    <WaveLeftIcon iconColor={currentMusicWaveColor || musicWaveColor} />
                    <Heading
                        className={'text-base-font-color'}
                        level={5}
                        text={inLineBusModalData?.subTitle?.value}
                        style={{ color: '#FFFFFF' }}
                    />
                    <Table
                        rootClassName={cn('w-full mt-5', styles.inLienBusTable)}
                        rowClassName={(_recode, index) => {
                            return cn(
                                'text-center',
                                index % 2 === 0 ? 'bg-[#EFEFEF]' : 'bg-[#f9f9f9]',
                            );
                        }}
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                    />
                    <Text
                        level={'body3'}
                        text={inLineBusModalData?.remark?.value}
                        className={'text-base-font-color mt-5'}
                    />
                </Flex>
            </Flex>
        </>,
        {
            token: {
                colorBgContainer: '#fff',
                colorText: '#000',
            },
            components: {
                Table: {
                    headerColor: '#000',
                    rowHoverBg: 'transparent',
                    borderColor: '#fff',
                },
            },
        },
    );
};

export default InLineBusTypeType;
